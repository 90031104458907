import BIRDS from 'vanta/dist/vanta.birds.min';
import FOG from 'vanta/dist/vanta.fog.min';
import WAVES from 'vanta/dist/vanta.waves.min';
import CLOUDS from 'vanta/dist/vanta.clouds.min';
import GLOBE from 'vanta/dist/vanta.globe.min';
import NET from 'vanta/dist/vanta.net.min';
import CELLS from 'vanta/dist/vanta.cells.min';
import RINGS from 'vanta/dist/vanta.rings.min';
import * as THREE from "three";
import React, { Suspense, useRef, useState, useEffect } from "react";
import { Canvas, useFrame } from "@react-three/fiber";
import Model from "./Model";
import {Button} from 'reactstrap';
import { TwitterPicker } from 'react-color';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


function Plane({ ...props }) {
  return (
    <mesh {...props} receiveShadow>
      <planeBufferGeometry args={[500, 500, 1, 1]} />
      <shadowMaterial transparent opacity={0.2} />
    </mesh>
  )
}

function App() {
  const d = 8.25
  const mouse = useRef({ x: 0, y: 0 });
  const [jointColor, setJointColor] = useState('#000000');
  const [skeletonColor, setSkeletonColor] = useState('#f36d05');
  const [globeColor, setGlobeColor] = useState('#f36d05');
  const [skeletonColorPicker, setSkeletonColorPicker] = useState(false);
  const [jointColorPicker, setJointColorPicker] = useState(false);

  const [vantaEffect, setVantaEffect] = useState(0);
  const [currentScene, setCurrentScene] = useState(0);
  const [customizeBar, setCustomizeBar] = useState(false);

  const backgroundEffect = [BIRDS, FOG, WAVES, CLOUDS, GLOBE, NET, CELLS, RINGS];
  const availableColors = ['#000000', '#FFFFFF', '#f36d05',"#f44336", "#e91e63", "#9c27b0", "#673ab7", "#3f51b5", "#2196f3","#03a9f4", "#00bcd4", "#009688","#4caf50", "#8bc34a", "#cddc39","#ffeb3b", "#ffc107", "#ff9800","#ff5722", "#795548", "#607d8b"];
  const globeRef = useRef(null);
  const popover = {
    position: 'absolute',
    zIndex: '2',
    top: '20px',
    left: '20px'
  }
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  }

  const openCustomizeBar = () => {
    setCustomizeBar(true);
  }

  const closeCustomizeBar = () => {
    setCustomizeBar(false);
  }


  const openSkeletonColorPicker = () => {
    setSkeletonColorPicker(true)
  }

  const openJointColorPicker = () => {
    setJointColorPicker(true)
  }

  const closeColorPicker = () => {
    setSkeletonColorPicker(false)
    setJointColorPicker(false)
  }

  const rotateCurrentScene = () => {
    setVantaEffect(0);
    if(currentScene < backgroundEffect.length - 1){
        setCurrentScene(currentScene + 1);
    } else {
        setCurrentScene(0);
    }
  }
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(backgroundEffect[currentScene]({
        THREE,
        el: globeRef.current,
        color: 0xf36d05,
        backgroundColor: 0x272727
      }))
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy()
    }
  }, [vantaEffect])

  return (
    <div className="angularbee" ref={globeRef}>
        <div className="container" >
          <div className="row title-row">
          </div>
          <div className="row canvas-row">
            <Canvas className="canvas" shadows dpr={[1, 1.5]} camera={{ position: [0, -3, 20] }}>
              <hemisphereLight skyColor={"black"} groundColor={0xf1f1f1} intensity={0.5} position={[0, 50, 0]} />
              <directionalLight
                position={[-8, 20, 8]}
                shadow-camera-left={d * -1}
                shadow-camera-bottom={d * -1}
                shadow-camera-right={d}
                shadow-camera-top={d}
                shadow-camera-near={0.1}
                shadow-camera-far={1500}
                castShadow
              />
              <mesh position={[0, 0, -10]}>
                <circleBufferGeometry args={[8, 64]} />
                <meshBasicMaterial color="#90a4ae" />
              </mesh>
              <Plane rotation={[-0.5 * Math.PI, 0, 0]} position={[0, -10, 0]} />
              <Suspense fallback={null}>
                <Model mouse={mouse} jointColor={jointColor} skeletonColor={skeletonColor} position={[0, -10, 0]} scale={[0.08, 0.08, 0.08]} />
              </Suspense>
            </Canvas>
          </div>

          {customizeBar ?
            <>
            <div className="row action-row">
                <div className="col-md-4">
                  <Button color={'secondary'}  block onClick={() => {openJointColorPicker()}}>Change Joint Color <span className="color-picker" style={{'background': jointColor, 'color': '#fff'}}></span></Button>
                  { jointColorPicker ?
                  <div style={ popover }>
                  <div style={ cover } onClick={ closeColorPicker }/>
                    <TwitterPicker colors={availableColors} triangle="hide"  color={ jointColor }
                    onChangeComplete={(color) => {setJointColor(color.hex)}}/>
                  </div> : null }
                </div>
                <div className="col-md-4">
                  <Button color={'secondary'}  block onClick={() => {openSkeletonColorPicker()}}>Change Skeleton Color <span className="color-picker" style={{'background': skeletonColor, 'color': '#fff'}}></span></Button>
                  { skeletonColorPicker ?
                  <div style={ popover }>
                  <div style={ cover } onClick={ closeColorPicker }/>
                    <TwitterPicker colors={availableColors} triangle="hide"  color={ skeletonColor }
                      onChangeComplete={(color) => {setSkeletonColor(color.hex)}}
                     />
                  </div> : null }
                </div>
                <div className="col-md-4">
                  <Button color={'danger'} block onClick={() => {closeCustomizeBar()}}>Close</Button>
                </div>
            </div>
            </> :
            <>
            <div className="row action-row">
                <div className="col-md-6">
                  <Button color={'primary'} block onClick={() => {rotateCurrentScene()}}>Change Scene</Button>
                </div>
                <div className="col-md-6">
                  <Button color={'warning'} block onClick={() => {openCustomizeBar()}}>Customize</Button>
                </div>
            </div>

            </>}

      </div>
    </div>
  )
}

export default App;
